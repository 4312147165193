import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Button from "../components/Button";
import ClickableDiv from "../components/ClickableDiv";

import MakingMemories from "../svg/logo/Making_memories.svg";
import Facebook from "../svg/social/Facebook.svg";
import Twitter from "../svg/social/Twitter.svg";
import Share from "../svg/social/Share.svg";

import { bodyLargeStyles, device } from "../globalStyles";

import { useErrorsContext } from "../context/errorsContext";
import { useLangContext } from "../context/langContext";
import { useCountryContext } from "../context/countryContext";

import I18N from "../constants/languages/NL/nl_translations";

import Seo, {DOMAIN, SEO_DESCRIPTION} from "../components/SEO";

const StyledLayout = styled(Layout)`
  background: var(--blue);
  color: white;

  --footer-height: 248px;
`;

const Content = styled.div`
  position: relative;
  padding: 0 var(--gutter);
  min-height: calc(
    100 * var(--vh) - var(--footer-height) - var(--header-height)
  );

  @media (${device.minTablet}) {
    padding-top: 0;
    margin-top: calc(-1 * var(--header-height));
  }
`;

const TextContainer = styled.div`
  margin: auto;
  max-width: 872px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 100%;
    margin-bottom: 32px;
  }

  @media (${device.minTablet}) {
    height: calc(100 * var(--vh));
  }
`;

const H3 = styled.h3`
  margin-bottom: 24px;
`;

const BodyLarge = styled.p`
  ${bodyLargeStyles};
  margin-bottom: 32px;
  @media (${device.minTablet}) {
    margin-bottom: 40px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 64px;

  & > * {
    width: 100%;
    &:first-child {
      margin-bottom: 16px;
    }
  }

  @media (${device.minMobileL}) {
    width: unset;
    flex-direction: row;
    & > *:first-child {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
`;

const FixedFooter = styled(Footer)`
  @media (${device.minTablet}) {
    bottom: 0;
    left: 0;
    position: fixed;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;

  @media (${device.minTablet}) {
    width: unset;
  }

  & > button {
    width: 100%;
    @media (${device.minMobileL}) {
      width: 200px;
    }
  }
`;

const SocialContainer = styled.div`
  padding: 24px;
  border-radius: 4px;
  background: var(--dark-blue-1);
  margin-bottom: 64px;

  & > p {
    margin-bottom: 32px;
  }
`;

const SocialMedia = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  margin: auto;
`;

const DisappearingText = styled.span`
  transition: opacity 0.75s var(--cubic-in-out);
  opacity: 0;
  color: white;
  position: absolute;
  left: calc(100% + 16px);
  top: 0;
  width: 100px;
`;

const FinalPage = () => {
  const { errors } = useErrorsContext();
  const hasMistakes = errors > 1;

  const [hasCopiedLink, setHasCopiedLink] = useState(false);

  const { lang } = useLangContext();
  const copy = I18N[lang].final;

  const { country } = useCountryContext();

  const copyToClipboard = () => {
    if (!navigator.clipboard) return;
    navigator.clipboard.writeText(window.location.origin);
    setHasCopiedLink(true);
    setTimeout(() => setHasCopiedLink(false), 3000);
  };

  const shareWebsite = () => {
    if (navigator.share !== undefined) {
      const title = "KLM making memories";
      const text = copy.share_message;
      const url = window.location.origin;

      navigator
          .share({
            title,
            text,
            url,
          })
          .catch((error) => {
            console.error(error);
            copyToClipboard();
          });
    } else {
      copyToClipboard();
    }
  };

  return (
      <StyledLayout
          title={copy.pageTitle}
          blue
          pageName={"Result page - " + hasMistakes ? "fail" : "success"}
      >
        <Content>
          <TextContainer>
            <MakingMemories />
            <H3>{hasMistakes ? copy.title_fail : copy.title_success}</H3>
            <BodyLarge>
              {hasMistakes ? copy.subtitle_fail : copy.subtitle_success}
            </BodyLarge>
            <ButtonsContainer>
              <StyledLink
                  as="a"
                  href={`https://www.klm.${country}/${lang}`}
                  rel="noreferrer"
              >
                <Button blue>{copy.button_stop}</Button>
              </StyledLink>
              <StyledLink to="/play">
                <Button>{copy.button_play}</Button>
              </StyledLink>
            </ButtonsContainer>
          </TextContainer>
        </Content>

        <FixedFooter />
      </StyledLayout>
  );
};

export default FinalPage;

export const Head = () => <Seo />;
