import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Facebook from "../svg/social/Facebook.svg";
import Instagram from "../svg/social/Instagram.svg";
import Twitter from "../svg/social/Twitter.svg";

import { device } from "../globalStyles";

import { useLangContext } from "../context/langContext";
import { useCountryContext } from "../context/countryContext";

import I18N from "../constants/languages/NL/nl_translations";

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px var(--gutter);
  width: 100%;
  background: var(--blue);
  z-index: 10;
  color: white;

  ul {
    display: flex;
    align-items: center;
    li {
      margin-right: 2.4rem;
      font-size: 12px;
      line-height: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  a {
    font-size: 12px;
    line-height: 16px;
    color: white;
    text-decoration: none;
  }

  @media (${device.minTablet}) {
    flex-direction: row;
    align-items: center;
  }
`;

const SocialMedia = styled.ul`
  white-space: nowrap;
  justify-content: space-between;
  min-width: auto;
  @media (${device.minTablet}) {
    justify-content: unset;
    order: 3;
  }
  @media (${device.minLaptop}) {
    min-width: 200px;
  }
`;

const LegalLinks = styled.ul`
  white-space: nowrap;
  flex-direction: column;
  && {
    align-items: unset;
  }
  & > * {
    margin-bottom: 24px;
  }
  @media (${device.minTablet}) {
    flex-direction: row;
    order: 2;
    & > * {
      margin-bottom: 0;
    }
  }
`;

const Copyright = styled.span`
  font-size: 12px;
  line-height: 16px;
  min-width: auto;
  white-space: nowrap;
  @media (${device.minTablet}) {
    order: 1;
  }
  @media (${device.minLaptop}) {
    min-width: 200px;
  }
`;

const HR = styled.hr`
  width: 100%;
  color: white;
  border-color: white;
  margin: 24px 0;
  @media (${device.minTablet}) {
    display: none;
  }
`;

const Footer = ({ className }: { className?: string }) => {
  const { lang } = useLangContext();
  const copy = I18N[lang].landing;

  const { country } = useCountryContext();

  return (
    <StyledFooter className={className}>
      <SocialMedia>
        <li>{copy.follow}</li>
        <li>
          <a
            href="https://www.facebook.com/KLM/"
            target="_blank"
            rel="noreferrer"
          >
            <Facebook />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/klm/"
            target="_blank"
            rel="noreferrer"
          >
            <Instagram />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/KLM" target="_blank" rel="noreferrer">
            <Twitter />
          </a>
        </li>
      </SocialMedia>

      <HR />

      <LegalLinks>
        <li>
          <Link to={"/terms-conditions"}>{copy.campaign}</Link>
        </li>
        <li>
          <a
            href={`https://www.klm.${country}/${lang}/information/legal/privacy-policy`}
            target="_blank"
            rel="noreferrer"
          >
            {copy.privacy}
          </a>
        </li>
        <li>
          <a
            href={`https://www.klm.${country}/${lang}/information/legal/cookie-policy`}
            target="_blank"
            rel="noreferrer"
          >
            {copy.cookie}
          </a>
        </li>
      </LegalLinks>

      <Copyright>© 2022 KLM</Copyright>
    </StyledFooter>
  );
};

export default Footer;
